import PageLoader from "components/loaders/pageLoader";
import { useProfile } from "hooks/api/users/queries/profile";
import { useUser } from "hooks/user";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ReactComponent as ClipboardSvg } from "static/images/calendar.svg";
import { ReactComponent as CommunitySvg } from "static/images/community.svg";
import { ReactComponent as ExampleAvatarSvg } from "static/images/example-avatar.svg";
import { ReactComponent as LogoutSvg } from "static/images/log-out.svg";
import { ReactComponent as LogoSvg } from "static/images/logo.svg";
import { ReactComponent as SettingsSvg } from "static/images/settings.svg";
import { ReactComponent as UserSvg } from "static/images/user.svg";

import "./styles.scss";

export default function NavBar() {
  const { t } = useTranslation("b_navbar", { keyPrefix: "pages" });
  const { data: profileUser, isLoading: isLoadingProfileUser } = useProfile();
  const { getPlaceholderUser } = useUser();

  if (isLoadingProfileUser || !profileUser) {
    return PageLoader();
  }

  return (
    <nav className="nav-bar">
      <main className="nav-bar--main">
        <header className="nav-bar--header">
          <div className="nav-bar--user-main-info">
            <ExampleAvatarSvg />
            <span className="nav-bar--heading">
              {getPlaceholderUser(
                profileUser.first_name,
                profileUser.last_name,
                profileUser.username
              )}
            </span>
          </div>
          <p className="nav-bar--job-info">
            {profileUser.job?.title || "Не указано"}
          </p>
        </header>
        <ul className="nav-bar--list">
          {/*<li className="nav-bar--list--item">
            <MessagesSvg />
            <NavLink className="nav-bar--list--link" to="/chats">
              Чаты
            </NavLink>
          </li>*/}
          <li className="nav-bar--list--item">
            <ClipboardSvg />
            <NavLink className="nav-bar--list--link" to="/board">
              {t("board")}
            </NavLink>
          </li>
          <li className="nav-bar--list--item">
            <CommunitySvg />
            <NavLink className="nav-bar--list--link" to="/community">
              {t("community")}
            </NavLink>
          </li>
          <li className="nav-bar--list--item">
            <SettingsSvg />
            <NavLink className="nav-bar--list--link" to="/settings">
              {t("settings")}
            </NavLink>
          </li>
          <li className="nav-bar--list--item">
            <UserSvg />
            <NavLink className="nav-bar--list--link" to="/profile">
              {t("profile")}
            </NavLink>
          </li>
          <li className="nav-bar--list--item">
            <LogoutSvg />
            <NavLink
              to="/"
              className="nav-bar--list--link"
              onClick={() => {
                localStorage.clear();
                location.reload();
              }}
            >
              {t("logout")}
            </NavLink>
          </li>
        </ul>
      </main>
      <footer className="nav-bar--footer">
        <LogoSvg />
      </footer>
    </nav>
  );
}
