import { lazy } from "react";

import { Route } from "./types";

const IndexPageResolver = lazy(() => import("../../pages/index"));
const ProfilePageResolver = lazy(() => import("../../pages/profile"));
const RegisterConfirmPageResolver = lazy(
  () => import("../../pages/confirmRegister")
);
const ChatsPageResolver = lazy(() => import("../../pages/chats"));
const CommunityPageResolver = lazy(() => import("../../pages/community"));
const CommunityPageItemResolver = lazy(
  () => import("../../pages/community/_/{id}")
);
const CommunityPageCreateResolver = lazy(
  () => import("../../pages/community/_/createProject")
);
const CommunityPageEditResolver = lazy(
  () => import("../../pages/community/_/editProject")
);
const BoardPageResolver = lazy(() => import("../../pages/board"));
const BoardIdPageResolver = lazy(() => import("../../pages/board/_/{id}"));
const ProfileEditPageResolver = lazy(() => import("../../pages/profileEdit"));
const SettingsPageResolver = lazy(() => import("../../pages/settings"));

const Routes: Route[] = [
  {
    path: "/",
    component: IndexPageResolver,
    settings: {
      exact: true,
    },
    layoutSettings: {},
  },
  {
    path: "/profile",
    component: ProfilePageResolver,
    settings: {
      exact: true,
    },
    layoutSettings: {
      renderFooter: false,
      renderHeader: false,
      renderNavBar: true,
      renderSupport: false,
    },
  },
  {
    path: "/register-confirm/*",
    component: RegisterConfirmPageResolver,
    settings: {
      exact: false,
    },
    layoutSettings: {},
  },
  {
    path: "/profile/edit",
    component: ProfileEditPageResolver,
    settings: {
      exact: false,
    },
    layoutSettings: {
      renderFooter: false,
      renderHeader: false,
      renderNavBar: true,
    },
  },
  {
    path: "/board",
    component: BoardPageResolver,
    settings: {
      exact: true,
    },
    layoutSettings: {
      renderFooter: false,
      renderHeader: false,
      renderNavBar: true,
    },
  },
  {
    path: "/board/*",
    component: BoardIdPageResolver,
    settings: {
      exact: false,
    },
    layoutSettings: {
      renderFooter: false,
      renderHeader: false,
      renderNavBar: true,
    },
  },
  {
    path: "/community",
    component: CommunityPageResolver,
    settings: {
      exact: true,
    },
    layoutSettings: {
      renderFooter: false,
      renderHeader: false,
      renderNavBar: true,
    },
  },
  {
    path: "/community/create",
    component: CommunityPageCreateResolver,
    settings: {
      exact: false,
    },
    layoutSettings: {
      renderFooter: false,
      renderHeader: false,
      renderNavBar: true,
    },
  },
  {
    path: "/community/edit/*",
    component: CommunityPageEditResolver,
    settings: {
      exact: false,
    },
    layoutSettings: {
      renderFooter: false,
      renderHeader: false,
      renderNavBar: true,
    },
  },
  {
    path: "/community/*",
    component: CommunityPageItemResolver,
    settings: {
      exact: false,
    },
    layoutSettings: {
      renderFooter: false,
      renderHeader: false,
      renderNavBar: true,
    },
  },
  {
    path: "/settings",
    component: SettingsPageResolver,
    settings: {
      exact: false,
    },
    layoutSettings: {
      renderFooter: false,
      renderHeader: false,
      renderNavBar: true,
    },
  },
  {
    path: "/chats",
    component: ChatsPageResolver,
    settings: {
      exact: false,
    },
    layoutSettings: {
      renderFooter: false,
      renderHeader: false,
      renderNavBar: true,
    },
  },
];

export default Routes;
