import {
  Company_Response,
  SignUp_RequestBody,
} from "api/endpoints/users/types";
import Button from "components/button";
import Input from "components/input";
import Modal from "components/modal";
import Select from "components/select";
import useSignIn from "hooks/api/users/mutations/signIn";
import useSignUp from "hooks/api/users/mutations/signUp";
import { useCompany } from "hooks/api/users/queries/company";
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { ReactComponent as CloseSvg } from "static/images/exit.svg";

import "./styles.scss";

type AuthorizationType = {
  password2: string;
} & SignUp_RequestBody;

interface Props {
  isOpen: boolean;
  onClose?: () => void;
}

export default function AuthorizationModal(props: Props) {
  const [isOpenLogIn, setIsOpenLogIn] = useState(true);

  const { data: companyData } = useCompany<Company_Response[]>();
  const signIn = useSignIn();
  const signUp = useSignUp();

  const { control, handleSubmit, setError } = useForm<AuthorizationType>({
    mode: "onChange",
  });

  function onSubmit(data: AuthorizationType) {
    if (data.username && data.email && data.password) {
      if (isOpenLogIn) {
        signIn.mutate(
          {
            data: {
              username: data.username,
              email: data.email,
              password: data.password,
            },
          },
          {
            onSuccess: (res) => {
              localStorage.setItem("accessToken", res.data.tokens.access);
              location.replace("/profile");
            },
            onError: () => {
              setError("username", { message: "Неправильные учетные данные!" });
              setError("email", { message: "Неправильные учетные данные!" });
              setError("password", { message: "Неправильные учетные данные!" });
            },
          }
        );
      } else {
        if (data.company_id && data.password && data.password2) {
          if (data.password !== data.password2) {
            setError("password", { message: "Пароли должны совпадать!" });
            setError("password2", { message: "Пароли должны совпадать!" });
          } else {
            signUp.mutate(
              {
                data: {
                  username: data.username,
                  email: data.email,
                  password: data.password,
                  company_id: Number(data.company_id),
                },
              },
              {
                onSuccess: () =>
                  alert("Заявка на регистрацию отправлена директору компании!"),
                onError: () => alert("Ошибка создания!"),
              }
            );
          }
        }
      }
    }
  }

  function changeIsOpenLogIn() {
    setIsOpenLogIn(!isOpenLogIn);
  }

  function renderSignIn() {
    return (
      <article className="authorization">
        <header className="authorization--header">
          <h2>Вход</h2>
          <div className="close-image" onClick={props.onClose}>
            <CloseSvg />
          </div>
        </header>
        <form
          className="authorization--inputs"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            control={control}
            name="username"
            render={({
              field: { ref, name, onChange, onBlur, value },
              fieldState,
            }) => (
              <Input
                label="Логин"
                required
                value={value}
                ref={ref}
                name={name}
                fullWidth
                placeholder="Введите логин"
                onChange={onChange}
                onBlur={onBlur}
                hasError={fieldState.error != null}
                errorMessage={fieldState.error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            render={({
              field: { ref, name, onChange, onBlur, value },
              fieldState,
            }) => (
              <Input
                label="Почта"
                required
                value={value}
                type="email"
                ref={ref}
                name={name}
                fullWidth
                placeholder="Введите адрес электроной почты"
                onChange={onChange}
                onBlur={onBlur}
                hasError={fieldState.error != null}
                errorMessage={fieldState.error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            render={({
              field: { ref, name, onChange, onBlur, value },
              fieldState,
            }) => (
              <Input
                label="Пароль"
                required
                type="password"
                value={value}
                ref={ref}
                name={name}
                fullWidth
                placeholder="Введите пароль"
                onChange={onChange}
                onBlur={onBlur}
                hasError={fieldState.error != null}
                errorMessage={fieldState.error?.message}
              />
            )}
          />
          <Button fullWidth label="Войти" type="submit" />
        </form>
        <div className="authorization--actions">
          <span className="authorization--actions--item">Забыли пароль?</span>
          <span
            className="authorization--actions--item"
            onClick={changeIsOpenLogIn}
          >
            Зарегистрироваться
          </span>
        </div>
        <footer className="authorization-footer">
          При регистрации и входе вы соглашаетесь с{" "}
          <u>условиями использования сервиса</u> и{" "}
          <u>политикой обработки данных</u>
        </footer>
      </article>
    );
  }

  function renderSignUp() {
    return (
      <article className="authorization">
        <header className="authorization--header">
          <h2>Регистрация</h2>
          <div className="close-image" onClick={props.onClose}>
            <CloseSvg />
          </div>
        </header>
        <form
          className="authorization--inputs"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            control={control}
            name="username"
            render={({ field: { ref, name, onChange, onBlur, value } }) => (
              <Input
                label="Логин"
                required
                value={value}
                ref={ref}
                name={name}
                fullWidth
                placeholder="Введите логин"
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            render={({ field: { name, onChange, onBlur, value } }) => (
              <Input
                label="Почта"
                required
                type="email"
                value={value}
                name={name}
                fullWidth
                placeholder="Введите адрес электроной почты"
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            render={({
              field: { ref, name, onChange, onBlur, value },
              fieldState,
            }) => (
              <Input
                label="Пароль"
                required
                type="password"
                value={value}
                ref={ref}
                name={name}
                fullWidth
                placeholder="Введите пароль"
                onChange={onChange}
                onBlur={onBlur}
                hasError={fieldState.error != null}
                errorMessage={fieldState.error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="password2"
            render={({
              field: { ref, name, onChange, onBlur, value },
              fieldState,
            }) => (
              <Input
                label="Повтор пароля"
                required
                type="password"
                value={value}
                ref={ref}
                name={name}
                fullWidth
                placeholder="Повторите пароль"
                onChange={onChange}
                onBlur={onBlur}
                hasError={fieldState.error != null}
                errorMessage={fieldState.error?.message}
              />
            )}
          />
          {companyData && (
            <Controller
              control={control}
              name="company_id"
              render={({ field: { ref, name, onChange, onBlur, value } }) => (
                <Select
                  label="Компания"
                  required
                  ref={ref}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fullWidth
                  options={companyData}
                  name={name}
                  placeholder="ID компании"
                />
              )}
            />
          )}
          <Button fullWidth label="Зарегистрироваться" type="submit" />
        </form>
        <div className="authorization--actions">
          <span
            className="authorization--actions--item"
            onClick={changeIsOpenLogIn}
          >
            Уже есть аккаунт
          </span>
        </div>
        <footer className="authorization-footer">
          При регистрации и входе вы соглашаетесь с{" "}
          <u>условиями использования сервиса</u> и{" "}
          <u>политикой обработки данных</u>
        </footer>
      </article>
    );
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      {isOpenLogIn ? renderSignIn() : renderSignUp()}
    </Modal>
  );
}
