export function useUser() {
  function getPlaceholderUser(
    first_name: string,
    last_name: string,
    username: string
  ) {
    if (first_name === "" && last_name === "") {
      return username;
    } else if (first_name !== "" && last_name === "") {
      return first_name;
    } else if (first_name === "" && last_name !== "") {
      return last_name;
    } else {
      return last_name + " " + first_name;
    }
  }

  function getFullPlaceholderUser(
    first_name: string | undefined,
    last_name: string | undefined,
    patronymic: string | undefined
  ) {
    let userFullName = "";

    if (last_name && last_name !== "") {
      userFullName += last_name;
    } else {
      userFullName += "Не указано";
    }

    if (first_name && first_name !== "") {
      userFullName += " " + first_name;
    } else {
      userFullName += " " + "Не указано";
    }

    if (patronymic && patronymic !== "") {
      userFullName += " " + patronymic;
    } else {
      userFullName += " " + "Не указано";
    }

    if (userFullName !== "") {
      return userFullName;
    } else {
      return "Не указано";
    }
  }

  return { getPlaceholderUser, getFullPlaceholderUser };
}
