import AuthorizationModal from "components/modals/authorizationModal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { ReactComponent as CloseMenuSvg } from "static/images/exit.svg";
import { ReactComponent as LanguageSvg } from "static/images/language.svg";
import { ReactComponent as LogoSvg } from "static/images/logo.svg";
import { ReactComponent as AlignMenuSvg } from "static/images/menu-align.svg";

import "./styles.scss";

export default function Header() {
  const { t } = useTranslation("b_header");

  const [isOpenSideBar, setIsOpenSideBar] = useState(false);
  const [isOpenAuthorization, setIsOpenAuthorization] = useState(false);

  useEffect(() => {
    document.body.style.overflow =
      isOpenSideBar || isOpenAuthorization ? "hidden" : "auto";
  }, [isOpenSideBar, isOpenAuthorization]);

  const header = document.querySelector("header");
  window.addEventListener("scroll", (e) => {
    if (e.target && header && window.scrollY > 0) {
      header.classList.add("scroll");
    } else {
      if (header) {
        header.classList.remove("scroll");
      }
    }
  });

  function changeOpenAuthorization() {
    setIsOpenAuthorization(!isOpenAuthorization);
  }

  function renderNavigation() {
    return (
      <nav>
        <ul className="navigation--list">
          <li>
            <NavLink
              to="#"
              className="navigation--item-link"
              onClick={changeOpenAuthorization}
            >
              {t("navigation.settings")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="#"
              className="navigation--item-link"
              onClick={changeOpenAuthorization}
            >
              {t("navigation.contacts")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="#"
              className="navigation--item-link"
              onClick={changeOpenAuthorization}
            >
              {t("navigation.tasks")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="#"
              className="navigation--item-link"
              onClick={changeOpenAuthorization}
            >
              {t("navigation.community")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="#"
              className="navigation--item-link"
              onClick={changeOpenAuthorization}
            >
              {t("navigation.statistics")}
            </NavLink>
          </li>
        </ul>
      </nav>
    );
  }

  return (
    <>
      <AuthorizationModal
        isOpen={isOpenAuthorization}
        onClose={changeOpenAuthorization}
      />
      <header className="header">
        <div className="header--wrapper">
          <div className="mobile-menu">
            <input
              type="checkbox"
              id="sidebar-checkbox"
              checked={isOpenSideBar}
              onChange={() => setIsOpenSideBar(!isOpenSideBar)}
              hidden
            />
            <label htmlFor="sidebar-checkbox" className="mobile-menu-icon">
              {isOpenSideBar ? <CloseMenuSvg /> : <AlignMenuSvg />}
            </label>
          </div>
          <Link to="/">
            <LogoSvg />
          </Link>
          <div className="navigation--wrapper">{renderNavigation()}</div>
          <div className="header--change-block">
            <div className="language-block">
              <LanguageSvg />
            </div>
            <Link to="#" className="log-in" onClick={changeOpenAuthorization}>
              {t("login")}
            </Link>
          </div>
        </div>
        <div className={`sidebar ${isOpenSideBar ? "checked" : ""}`}>
          {renderNavigation()}
        </div>
      </header>
    </>
  );
}
