import React, { ReactNode, useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import "./style.scss";

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  children?: ReactNode;
}

const modalRootElement = document.getElementById("modal");

export default function Modal(props: Props) {
  const element = useMemo(() => document.createElement("modal"), []);

  useEffect(() => {
    if (props.isOpen) {
      modalRootElement?.appendChild(element);

      return () => {
        modalRootElement?.removeChild(element);
      };
    }
  });

  return props.isOpen
    ? createPortal(
        <>
          <div className="modal-container">
            <div className="modal-card">{props.children}</div>
            <div className="bg-closer" onClick={props.onClose} />
          </div>
        </>,
        element
      )
    : null;
}
