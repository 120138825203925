import React, {
  ChangeEvent,
  FocusEventHandler,
  RefCallback,
  RefObject,
  SelectHTMLAttributes,
} from "react";
import "./styles.scss";

export interface Option {
  id: number;
  title: string;
  defaultChecked?: boolean;
}

interface Props {
  name: string;
  ref?: RefObject<HTMLSelectElement> | RefCallback<HTMLSelectElement>;
  id?: string;
  options: Option[];
  value?: SelectHTMLAttributes<HTMLSelectElement>["value"];
  multiple?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  onSelectValue?: (id: number) => void;
  onBlur?: FocusEventHandler<HTMLSelectElement>;
  placeholder?: SelectHTMLAttributes<HTMLSelectElement>["aria-placeholder"];
  required?: boolean;
  lowSize?: boolean;
  label?: string;
}

export default function Select(props: Props) {
  return (
    <div>
      {props.label && (
        <div className="input--label">
          <label>{props.label}</label>
        </div>
      )}
      <select
        required={props.required}
        id={props.id}
        ref={props.ref}
        className={
          "select " +
          (props.fullWidth ? "fullWidth " : "") +
          (props.lowSize ? "lowSize " : "")
        }
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        multiple={props.multiple}
        disabled={props.disabled}
        onBlur={props.onBlur}
        aria-placeholder={props.placeholder}
      >
        <option className="select__option" />
        {props.options.map((option, key) => (
          <option className="select__option" value={option.id} key={key}>
            {option.title}
          </option>
        ))}
      </select>
    </div>
  );
}
