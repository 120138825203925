import http from "api/index";
import { PureResponse } from "api/types";
import { CancelToken } from "axios";

import { ChatCreate_RequestBody, ChatCreate_Response } from "./types";

export function createChat(
  data: ChatCreate_RequestBody,
  cancelToken?: CancelToken
): PureResponse<ChatCreate_Response> {
  return http.put("chat/create/", data, { cancelToken });
}
