import { createChat } from "api/endpoints/chat";
import {
  ChatCreate_RequestBody,
  ChatCreate_Response,
} from "api/endpoints/chat/types";
import { AxiosError, AxiosResponse } from "axios";
import { useMutation, UseMutationOptions } from "react-query";

type Data = AxiosResponse<ChatCreate_Response>;

type Error = AxiosError<{ code: string; message: string }>;

interface Variables {
  data: ChatCreate_RequestBody;
}

export default function useCreateProfile(
  options?: Omit<UseMutationOptions<Data, Error, Variables>, "mutationFn">
) {
  return useMutation<Data, Error, Variables>(
    (variables) => createChat(variables.data),
    options
  );
}
