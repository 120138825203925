import { signUp } from "api/endpoints/users";
import { SignUp_RequestBody, SignUp_Response } from "api/endpoints/users/types";
import { AxiosError, AxiosResponse } from "axios";
import { useMutation, UseMutationOptions } from "react-query";

type Data = AxiosResponse<SignUp_Response>;

type Error = AxiosError<{ code: string; message: string }>;

interface Variables {
  data: SignUp_RequestBody;
}

export default function useSignUp(
  options?: Omit<UseMutationOptions<Data, Error, Variables>, "mutationFn">
) {
  return useMutation<Data, Error, Variables>(
    (variables) => signUp(variables.data),
    options
  );
}
