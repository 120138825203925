import http from "api/index";
import { PureResponse } from "api/types";
import { CancelToken } from "axios";

import {
  ChangeJobUser_RequestBody,
  ChangeJobUser_ResponseBody,
  Profile_RequestBody,
  Profile_Response,
  SignIn_RequestBody,
  SignIn_Response,
  SignUp_RequestBody,
  SignUp_Response,
} from "./types";

export function signIn(
  data: SignIn_RequestBody,
  cancelToken?: CancelToken
): PureResponse<SignIn_Response> {
  return http.post("user/login/", data, { cancelToken });
}

export function signUp(
  data: SignUp_RequestBody,
  cancelToken?: CancelToken
): PureResponse<SignUp_Response> {
  return http.post("user/register/", data, { cancelToken });
}

export function updateProfile(
  data: Profile_RequestBody,
  cancelToken?: CancelToken
): PureResponse<Profile_Response> {
  return http.put("user/profile/", data, { cancelToken });
}

export function updateJobUser(
  id: number,
  data: ChangeJobUser_RequestBody,
  cancelToken?: CancelToken
): PureResponse<ChangeJobUser_ResponseBody> {
  return http.put(`user/${id}/change-job/`, data, { cancelToken });
}
