import { signIn } from "api/endpoints/users";
import { SignIn_RequestBody, SignIn_Response } from "api/endpoints/users/types";
import { AxiosError, AxiosResponse } from "axios";
import { useMutation, UseMutationOptions } from "react-query";

type Data = AxiosResponse<SignIn_Response>;

type Error = AxiosError<{ code: string; message: string }>;

interface Variables {
  data: SignIn_RequestBody;
}

export default function useSignIn(
  options?: Omit<UseMutationOptions<Data, Error, Variables>, "mutationFn">
) {
  return useMutation<Data, Error, Variables>(
    (variables) => signIn(variables.data),
    options
  );
}
