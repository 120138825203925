import LayoutBuilder from "components/layoutBuilder";
import React from "react";
import { Route, Switch } from "react-router-dom";

import Routes from "./services/router/config";

function App() {
  return (
    <Switch>
      <Route path="/" exact={false}>
        {() => (
          <Switch>
            {Routes.map((route) => (
              <Route
                key={route.path.toString()}
                path={route.path}
                exact={route.settings.exact}
                strict={route.settings.strict}
                sensitive={route.settings.sensitive}
                render={() => (
                  <LayoutBuilder
                    component={route.component}
                    renderFooter={route.layoutSettings.renderFooter}
                    renderHeader={route.layoutSettings.renderHeader}
                    renderNavBar={route.layoutSettings.renderNavBar}
                    renderSupport={route.layoutSettings.renderSupport}
                  />
                )}
              />
            ))}
          </Switch>
        )}
      </Route>
    </Switch>
  );
}

export default App;
