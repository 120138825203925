import http from "api";
import { Profile_Response } from "api/endpoints/users/types";
import { AxiosError } from "axios";
import { QueryFunction, useQuery, UseQueryOptions } from "react-query";

type Response = Profile_Response;

const url = "user/profile/";
type QueryKey = typeof url;

const getProfile: QueryFunction<Response, QueryKey> = async () => {
  return (await http.get(url)).data;
};

export const useProfile = <TData = Response>(
  options?: Omit<
    UseQueryOptions<Response, AxiosError, TData, QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  const { data, ...rest } = useQuery(url, getProfile, {
    refetchOnWindowFocus: false,
    ...options,
  });
  return { data, ...rest };
};
