import cn, { Argument as ClassNamesArgument } from "classnames";
import React, {
  FocusEventHandler,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  ReactNode,
  RefCallback,
  RefObject,
  useState,
} from "react";
import { ReactComponent as HidePasswordSwg } from "static/images/password.svg";
import { ReactComponent as ShowPasswordSwg } from "static/images/show-password.svg";
import { ReactComponent as Warning } from "static/images/warn.svg";
import "./styles.scss";

interface Props {
  lowSize?: boolean;
  ref?: RefObject<HTMLInputElement> | RefCallback<HTMLInputElement>;
  type?: HTMLInputTypeAttribute;
  classNames?: ClassNamesArgument;
  name?: string;
  id?: string;
  disabled?: boolean;
  label?: string;
  onClick?: () => void;
  onChange?: ({ ...event }) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  icon?: ReactNode;
  hasError?: boolean;
  errorMessage?: string;
  fullWidth?: boolean;
  readonly?: boolean;
  placeholder?: string;
  required?: boolean;
  defaultValue?: InputHTMLAttributes<HTMLInputElement>["value"];
  value?: InputHTMLAttributes<HTMLInputElement>["value"];
}

export default function Input(props: Props) {
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

  const {
    hasError,
    errorMessage,
    placeholder,
    fullWidth,
    classNames,
    name,
    id,
    disabled,
    onChange,
    onClick,
    icon,
    readonly,
    type,
    onBlur,
    ref,
    value,
    required,
    label,
    defaultValue,
    lowSize,
  } = props;

  const typeInput =
    type == "password" ? (isShowPassword ? "text" : "password") : type;

  return (
    <div
      className={cn("input", classNames, {
        "input--full-width": fullWidth,
      })}
    >
      {label && (
        <div className="input--label">
          <label>{label}</label>
        </div>
      )}
      <div
        className={cn("input--container", classNames, {
          "input--container--full-width": fullWidth,
          "input--container--warning": hasError,
        })}
      >
        <input
          defaultValue={defaultValue}
          required={required}
          value={value}
          ref={ref}
          name={name}
          id={id}
          type={typeInput}
          disabled={disabled}
          onChange={onChange}
          onClick={onClick}
          onBlur={onBlur}
          readOnly={readonly}
          placeholder={placeholder}
          className={"input--select-from" + (lowSize ? " low-size" : "")}
        />
        {icon && type != "password" && (
          <div className="input--after-icon">{icon}</div>
        )}
        {type == "password" && (
          <div
            className="input--after-icon"
            onClick={() => setIsShowPassword(!isShowPassword)}
          >
            {isShowPassword ? <ShowPasswordSwg /> : <HidePasswordSwg />}
          </div>
        )}
      </div>
      {hasError && (
        <div className="input--warning">
          <Warning />
          <label className="input--label-warning">{errorMessage}</label>
        </div>
      )}
    </div>
  );
}
