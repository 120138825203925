import Input from "components/input";
import useCreateProfile from "hooks/api/chat/mutations/createChat";
import { useProfile } from "hooks/api/users/queries/profile";
import { useUser } from "hooks/user";
import React, { useEffect, useState } from "react";
import { ReactComponent as AvatarMiniatureSvg } from "static/images/avatar-miniature.svg";
import { ReactComponent as ExitSvg } from "static/images/exit.svg";
import { ReactComponent as SendSvg } from "static/images/send-message.svg";

import "./styles.scss";

export default function Support() {
  const [isOpen, setIsOpen] = useState(false);

  const createProfile = useCreateProfile();
  const { data: profileData, isLoading: isLoadingProfile } = useProfile();

  const { getPlaceholderUser } = useUser();

  useEffect(
    () => {
      if (!isLoadingProfile && isOpen) {
        const username = profileData?.username ? profileData?.username : "";
        const first_name = profileData?.first_name
          ? profileData?.first_name
          : "";
        const last_name = profileData?.last_name ? profileData?.last_name : "";

        createProfile.mutate({
          data: {
            participants: [
              {
                username: username,
                first_name: first_name,
                last_name: last_name,
              },
            ],
          },
        });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="support">
      {isOpen ? (
        <div className="chat--wrapper">
          <header className="chat--header">
            <h3 className="chat--heading">Чат с поддержкой</h3>
            <div id="click" onClick={() => setIsOpen(false)}>
              <ExitSvg />
            </div>
          </header>
          <main className="chat--list">
            {[...Array(20)].map((item, index) => (
              <div className="message-support" key={index}>
                <AvatarMiniatureSvg />
                <div className="message-support--info">
                  <div className="message-support--user-header">
                    <h3 className="message-support--heading">
                      {getPlaceholderUser("Alex", "Taushkanov", "retex07")}
                    </h3>
                    <span className="message-support--description">12:10</span>
                  </div>
                  <p className="message-support--content">Hello</p>
                </div>
              </div>
            ))}
          </main>
          <footer className="chat--footer">
            <Input fullWidth placeholder="Напишите сообщение..." />
            <div>
              <SendSvg />
            </div>
          </footer>
        </div>
      ) : (
        <div className="support--wrapper" onClick={() => setIsOpen(true)}>
          <h3 className="support--heading">Поддержка</h3>
          <AvatarMiniatureSvg />
        </div>
      )}
    </div>
  );
}
